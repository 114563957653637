<template lang="pug">
include /mixins
div
  +b.trip-monthly-case.tt-space_mb--6(v-if='trip.trip_potential_participant && currentMonthDistribution')
    +e.head.tt-space_mb--4
      +e.title.ds-caption--size_2md.--semibold.SPAN {{ _('Общая сумма закупки') }} &nbsp;
        span(v-html='getSplittedHtml(trip.trip_potential_participant.props.purchase_amount, "€")')
      +e.deadline
        +b.ds-caption--size_xs.--medium.SPAN {{ _('внести до') }} {{ lastDay }}
        //- +b.tt-space_ml--2.SVG(width='20' height='20' viewbox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg')
          //- path(
            //- d='M10 0.5C15.2469 0.5 19.5 4.75315 19.5 10C19.5 15.2469 15.2469 19.5 10 19.5C4.75315 19.5 0.5 15.2469 0.5 10C0.5 4.75315 4.75315 0.5 10 0.5ZM10 1.82525C7.83192 1.82525 5.75264 2.68652 4.21958 4.21958C2.68652 5.75264 1.82525 7.83192 1.82525 10C1.82525 12.1681 2.68652 14.2474 4.21958 15.7804C5.75264 17.3135 7.83192 18.1748 10 18.1748C12.1681 18.1748 14.2474 17.3135 15.7804 15.7804C17.3135 14.2474 18.1748 12.1681 18.1748 10C18.1748 7.83192 17.3135 5.75264 15.7804 4.21958C14.2474 2.68652 12.1681 1.82525 10 1.82525ZM9.86225 8.14117C10.228 8.14117 10.5244 8.43852 10.5244 8.80427V15.1927C10.5179 15.3641 10.4453 15.5263 10.3217 15.6453C10.1981 15.7642 10.0333 15.8307 9.86178 15.8307C9.69026 15.8307 9.52541 15.7642 9.40185 15.6453C9.27829 15.5263 9.20564 15.3641 9.19915 15.1927V8.80332C9.19915 8.43757 9.49555 8.14117 9.86225 8.14117ZM9.88885 4.69805C10.1232 4.69805 10.3479 4.79113 10.5136 4.95682C10.6793 5.12251 10.7724 5.34723 10.7724 5.58155C10.7724 5.81587 10.6793 6.04059 10.5136 6.20628C10.3479 6.37197 10.1232 6.46505 9.88885 6.46505C9.65453 6.46505 9.42981 6.37197 9.26412 6.20628C9.09843 6.04059 9.00535 5.81587 9.00535 5.58155C9.00535 5.34723 9.09843 5.12251 9.26412 4.95682C9.42981 4.79113 9.65453 4.69805 9.88885 4.69805Z'
            //- fill='#821D76'
          //- )
    +e.progress.tt-space_mb--4
      linear-progress-bar(:progress="getPercent(trip.trip_potential_participant.props.total_actual_sale, trip.trip_potential_participant.props.purchase_amount)")
    +e.sum-info.tt-space_mb--6
      //- +e.sum-info-element
      //-   +b.sum.ds-caption--size_3md.--semibold.P {{ currentMonthDistribution.balance > 0 ? currentMonthDistribution.balance : 0 }}€
      //-   +b.ds-caption--size_xs.--medium.P {{ _('к оплате') }}
      +e.sum-info-element
        +b.sum.ds-caption--size_3md.--semibold.P(
          v-html='getSplittedHtml(trip.trip_potential_participant.props.total_actual_sale, "€")'
        )
        +b.ds-caption--size_xs.--medium.P {{ _('фактически куплено') }}
      +e.sum-info-element
        +b.sum.ds-caption--size_3md.--semibold.P(
          v-if='trip.trip_potential_participant.props.purchase_amount - trip.trip_potential_participant.props.total_actual_sale > 0'
          v-html='getSplittedHtml(trip.trip_potential_participant.props.purchase_amount - trip.trip_potential_participant.props.total_actual_sale, "€")'
        )
        +b.sum.ds-caption--size_3md.--semibold.P(
          v-else
          v-html='getSplittedHtml(0, "€")'
        )
        +b.ds-caption--size_xs.--medium.P {{ _('осталось') }}
  +b.trip-terms
    +b.g-row.--space_md.--space_xl-xl.--appearance_spaced.--justify_center-md.--justify_start-xl
      template(v-for='(item, index) in trip.conditions')
        +b.g-cell.g-cols--12.--auto-md
          +b.trip-terms-element
            +e.head.tt-space_mb--4
              template(v-if='trip.conditions && trip.conditions.length > 1')
                +e.index.ds-caption--size_xs.--medium.tt-space_mr--2 {{ index + 1 }}
              +e.title.ds-caption--size_2md.--medium.P {{ item.props.condition_name_for_sso }}
            +e.deposit-block.tt-space_mb--4
              +e.sum-block
                +b.ds-caption--size_sm.--medium.tt-space_mb--1.P {{ _('Общая сумма') }}:
                +e.sum.P
                  +b.deposit.ds-caption--size_lg.--semibold.SPAN(v-html='getSplittedHtml(item.props.actual_sale, "€")')
                  +b.full-amount.ds-caption--size_sm.--medium.SPAN &nbsp; / &nbsp;
                  +b.full-amount.ds-caption--size_sm.--medium.SPAN(v-html='getSplittedHtml(item.props.purchase_value, "€")')
              +b.percent.ds-caption--size_sm.--semibold.SPAN {{ getPercent(item.props.actual_sale, item.props.purchase_value) }}%
            +e.button
            +b.tt-button.A(
              :href="item.props.url"
              target="_blank"
            )
              +e.title {{ _('Выбрать товары для этой закупки') }}
</template>

<script>

import {
  mapState, mapActions,
} from 'vuex'

import { format } from 'date-fns'

import {
  COUNTRY_CODE_COOKIE_NAME,
  DEFAULT_DATE_FORMAT,
} from '@app/consts'

const CURRENT_MONTH_STATUS = 'current'

export default {
  props: {
    trip_label: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      DEFAULT_DATE_FORMAT,
    };
  },
  computed: {
    ...mapState('trip', ['trip']),
    currentMonthDistribution() {
      if (this.trip && this.trip.trip_potential_participant && this.trip.trip_potential_participant.props ) {
        return this.trip.trip_potential_participant.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS)
      }
      return null
    },
    lastDay() {
      return this.trip.trip_potential_participant.props.planned_date_of_redemption.split(' ')[0]
      // return format(this.trip.trip_potential_participant.props.planned_date_of_redemption, DEFAULT_DATE_FORMAT)
    },
  },
  // mounted() {
  //   this.getTrip(this.trip_label);
  // },
  methods: {
    format,
    ...mapActions('trip', ['getTrip']),
    // async getTripInfo() {
    //   const { data } = (await tripRetrieveResource.execute({ label: this.trip }))
    //   this.item = data.props
    // },
    getPercent(value, from) {
      return Math.round((Number(value) / Number(from)) * 100) || 0;
    },
    addCondition() {
      this.$emit('add-condition', this.condition);
      this.condition = {
        trip: this.trip,
        name: '',
        description: '',
      };
    },
  },
}

</script>
