<template lang="pug">
  include /mixins
  +b.post-feedback
    +e.title.H4 {{ info.title }}
    +e.icons
      template(v-for='icon in icons[info.type]')
        modal-trigger(
          url='Forms/ModalWrapper'
          tag='PostFeedbackForm'
          :title="icon.title",
          :subject='icon.subject',
          :pr='sendAndClose()'
          :message="icon.message"
        )
          template(#default='{ open }')
            +e.icon(@click='open')
              +e.img-wrap
                +e.img.IMG(:src='`/static/img/${icon.img}`')
              +e.label.SPAN {{ icon.label }}
    modal-trigger(
      url='Forms/ModalWrapper'
      tag='PostFeedbackForm'
      subject='205',
      :title="_('Оставьте данные для связи')",
      :pr='sendAndClose()'
      :message="{title: this._('Спасибо за интерес к статье! 205'), text: this._('Менеджер свяжеться с вами, чтобы ответить на ваш вопрос')}",
    )
      template(#default='{ open }')
        +e.bottom.A(
          @click.prevent='open'
        )
          +b.post-feedback-bottom
            +e.icon
              +e.img-wrap
                +e.img.IMG(:src='`/static/img/blog/request.svg`')
              +e.label.SPAN {{ _('Есть вопрос по косметологии') }}
</template>

<script>

const APPROVE = 'approve'
const REJECT = 'reject'

export default {
  name: 'PostFeedback',
  props: {
    info: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'approve',
    },
  },
  data() {
    return {
    }
  },
  computed: {
    icons() {
      return {
        [APPROVE]: [
          {
            img: 'blog/heart.svg',
            label: this._('Понравилось содержание статьи'),
            subject: 201,
            title: this._('Скажите, чем вам понравилась статья?'),
            modal: true,
            message: {
              title: this._('Спасибо за интерес к статье! 201'),
              text: this._('Мы рады, что вам понравилось содержание статьи.'),
            },
          },
          {
            img: 'blog/like.svg',
            subject: 202,
            label: this._('Эта статья была информативной'),
            title: this._('Скажите, что было полезного в статье?'),
            modal: true,
            message: {
              title: this._('Спасибо за интерес к статье! 202'),
              text: this._('Мы рады, что статья была вам информативна.'),
            },
          },
        ],
        [REJECT]: [
          {
            img: 'blog/info.svg',
            subject: 203,
            label: this._('Не корректная информацию'),
            title: this._('Подскажите, где вы нашли ошибку?'),
            modal: true,
            message: {
              title: this._('Спасибо за интерес к статье! 203'),
              text: this._('Мы постараемся улучшить статью.'),
            },
          },
          {
            img: 'blog/dislike.svg',
            subject: 204,
            label: this._('Нет нужной мне информации'),
            title: this._('Подскажите, что вас интересует?'),
            modal: true,
            message: {
              title: this._('Спасибо за интерес к статье! 204'),
              text: this._('Мы постараемся добавить необходимую информацию.'),
            },
          },
        ],
      }
    },
  },
  methods: {
    toPromise(arg) {
      if (!arg) return Promise.reject()

      return Promise.resolve(arg)
    },
    sendAndClose() {
      const pr = f => this.toPromise(f)
        .then(() => {
          console.log('object');
          this.$emit('close')
        }, err => {
          console.log('error', err);
          this.$emit('close')
        })
      return pr
    },
  },
}
</script>
