<template lang="pug">
  include /mixins
  vue-multiselect(
    :class="[{ 'is-multiple': multiple, 'is-searchable': searchable, error: errors[name] || !isValid, success: isSuccess }]"
    v-bind="$attrs"
    @open="$emit('open')"
    @search-change="$emit('search-change', $event)"
    :options="localOptions"
    :label="label"
    :track-by="trackBy"
    :placeholder="placeholder"
    :disabled="disabled"
    :allow-empty="allowEmpty"
    v-model="model"
    :required="rules"
    :multiple="multiple"
    :searchable="searchable"
    :show-labels="false"
    @input="check"
    :custom-label="customLabel"
    :close-on-select="!multiple"
  )
    template(
      v-slot:[name]="binded"
      v-for="(name, key) in eSlots"
    )
      slot(
        v-bind="binded"
        :name="key"
      )
    template(slot="footer" slot-scope="{ deactivate, clear }")
      .multiselect-close
        +b.g-row--align_center.--space_md
          +b.g-cell.g-cols
            +b.tt-button--mini.BUTTON(@click.stop.prevent="deactivate") {{ $t('Apply') }}
          +b.g-cell.g-cols
            +b.tt-button--mini.--variant_bordered-primary.BUTTON(@click.stop.prevent="clear(); deactivate()") {{ $t('Clear') }}
    template(slot="option" slot-scope="{ option }")
      slot(name="option" :option='option')
        +b.multiselect-checkbox(v-if="multiple")
          input(type="checkbox" v-model="option.selected" style="pointer-events: none;")
          label {{ getOptionLabel(option) }}
        p(v-else) {{ getOptionLabel(option) }}
    template(slot="noResult") {{ noResultText }}
    template(slot="noOptions") {{ noOptionsText }}
</template>

<script>
import VueMultiselect from 'wc-multiselect/src/index.js';
import { isEmpty } from 'lodash';

const eSlotsResolver = (acc, key) => {
  acc[key] = key
  return acc
}

const getESlots = keys => keys
  .reduce(eSlotsResolver, {})

export default {
  components: { VueMultiselect },
  props: {
    errors: { type: Object, default: () => ({}) },
    disabled: Boolean,
    isSuccess: Boolean,
    searchable: Boolean,
    options: Array,
    label: String,
    customLabel: {
      type: Function,
      default: (option, label) => (isEmpty(option) ? '' : label ? option[label] : option),
    },
    trackBy: { type: String, default: 'id' },
    allowEmpty: { type: Boolean, default: true },
    placeholder: { type: String, default: '' },
    rules: { type: String, default: '' },
    value: {},
    name: String,
    multiple: Boolean,
    inputClass: {
      type: String,
      // default: 'd-control-input__element',
    },
  },
  inheritAttrs: false,
  data() {
    return {
      model: this.value,
      isValid: null,
      noOptionsText: this.$t('List is empty.'),
      noResultText: this.$t('No elements found. Consider changing the search query.')
    };
  },
  watch: {
    value(newVal) {
      this.model = newVal;
    },
  },
  computed: {
    eSlots() {
      return getESlots(Object.keys(this.$scopedSlots))
    },
    localOptions() {
      return this.options.map((obj, idx) => {
        const o = obj
        const selected = Array.isArray(this.value)
            ? !!this.value.find((r) => obj[this.trackBy] == r[this.trackBy])
            : this.value && obj[this.trackBy] === this.value[this.trackBy]
        this.$set(obj, 'selected', selected)
        return obj
      });
    },
  },
  methods: {
    check(value) {
      this.$emit('input', value);
    },
    getOptionLabel(option) {
      if (isEmpty(option)) return '';
      return this.customLabel(option, this.label);
    },
  },
};
</script>
