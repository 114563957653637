<template lang="pug">
include /mixins
+b.ambassador-request
  +b.ambassador-steps
    +b.ambassador-step(
      v-for='step in steps'
      :key='step'
      @click='goNext(step)'
      :class='{ "is-active": step === currentStep, "is-past": step < currentStep }'
    ) 0{{ step }}
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    div
      +b.form-fields(
        v-for='{ fields, title } in form_fields[currentStep-1]'
        v-if='currentStep < 3'
      )
        +e.title.P {{ title }}
        +b.g-row
          +b.g-cell.g-cols--12.tt-space_mt--6(
            v-for='{ rules, name, label, tag, classname,events, errorClasses, mask, type, props } in fields'
          )
            tag(
              :tag='tag || "d-control-input"'
              :rules="rules",
              :input-label='label'
              v-model='form[name]',
              :ref='name'
              v-bind='props'
              v-on='events'
              @input='$emit("update", form)'
              :name='name'
              :type='type || "text"'
              :key='name'
              :class='classname',
              :error-classes='errorClasses'
            )
      +b.ambassador-form-summary(
        v-if='currentStep === 3',
      )
        +e.title-wrap
          +e.title.H4 {{ _('Проверьте, пожалуйста, указанную вами информацию') }}
          +e.btn.A(href='', @click.prevent='currentStep = 1') {{ _('Редактировать') }}
        +e.info(v-for='item in summary')
          +e.info-title.P {{ item.title }}
          +e.info-value.P(v-if='item.getter') {{ item.getter(form[item.value]) }}
          +e.info-value.P(v-else) {{ form[item.value] }}
        +b.ds-caption.--size_sm.--medium.--color_black.P {{ _('Нажимая кнопку “Отправить вы соглашаетесь с') }} &nbsp;
          +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='offer', target='_blank') {{ _('политикой конфиденциальности') }}
          span &nbsp; {{ _('и') }} &nbsp;
          +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='rules', target='_blank') {{ _('правилами пользования TOTIS Pharma') }}
      +b.tt-space_mt--6
        +b.tt-button--auto.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='goNext'
        )
          //- @click.prevent='prepareData(valid)'
          +e.title.P {{ currentStep === 3 ? _('Отправить заявку') : _('Далее') }}

</template>
<script>
import { createEventRequest } from '@api/events.service';

import { AnswerMessage } from '@utils/submit';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

import { decodeHTMLEntities } from '@utils/helpers'
import {
  getPaginatedPropsForArray,
} from '@utils/valuesGetter'

import {
  BRAND_LIST_URL,
} from '@api/catalog.service'
import {
  ambassadorRequestCreateResource,
} from '@api/vendor.service'

export default {
  name: 'AmbassadorForm',
  mixins: [FormMixin],
  data() {
    return {
      steps: [1, 2, 3],
      currentStep: 1,
      isLoad: false,
      offer: window.offer,
      rules: window.rules,
      form_fields: [[
        {
          title: this._('Личные данные'),
          fields: [
            {
              name: 'name',
              label: this._('ФИО'),
              rules: 'required',
            },
            {
              name: 'city',
              label: this._('Город, где вы проживаете'),
              rules: 'required',
            },
            {
              name: 'phone',
              label: this._('Номер телефона'),
              rules: 'required|phone',
              tag: 'phone-input',
            },
            {
              name: 'specialization',
              label: this._('Укажите свою специализацию'),
              rules: 'required',
            },
          ],
        },
        {
          title: this._('Ссылка на ваш Instagram аккаунт'),
          fields: [
            {
              name: 'instagram',
              label: this._('Ссылка'),
              rules: 'required',
            },
          ],
        },
        {
          title: this._('Ссылка на ваш Facebook аккаунт'),
          fields: [
            {
              name: 'facebook',
              label: this._('Ссылка'),
              rules: 'required',
            },
          ],
        },
        {
          title: this._('Как часто вы публикуете контент в соцсетях?'),
          fields: [
            {
              name: 'social_activity',
              label: this._('Частота публикаций'),
              rules: 'required',
            },
          ],
        },
      ], [
        {
          title: this._('Какие бренды TOTIS Pharma вы используете в работе?'),
          fields: [
            {
              label: this._('Выберите бренды'),
              rules: 'required',
              name: 'brands_used',
              tag: 'd-control-multiselect',
              events: {
                open: () => getPaginatedPropsForArray(BRAND_LIST_URL, '', 'brands_used', null, this.form_fields[1][0].fields),
                'search-change': $event => getPaginatedPropsForArray(BRAND_LIST_URL, $event, 'brands_used', null, this.form_fields[1][0].fields),
              },
              afterList: $event => getPaginatedPropsForArray(BRAND_LIST_URL, '', 'brands_used', $event, this.form_fields[1][0].fields),
              default: { id: 0, props: { title: this._('Не работаю з брендами TOTIS Pharma') } },
              props: {
                options: [],
                searchable: false,
                label: 'caption',
                'internal-search': false,
                search: '',
                multiple: true,
                taggable: false,
                pagination: {},
                customLabel: obj => obj.props?.title,
                // placeholder: this._('Выберите продукт'),
              },
            },
          ],
        },
        {
          title: this._('Какие бренды вы бы хотели представлять как амбассадор TOTIS Pharma?'),
          fields: [
            {
              label: this._('Выберите бренды'),
              rules: 'required',
              name: 'brands_wishlist',
              tag: 'd-control-multiselect',
              events: {
                open: () => getPaginatedPropsForArray(BRAND_LIST_URL, '', 'brands_wishlist', null, this.form_fields[1][1].fields),
                'search-change': $event => getPaginatedPropsForArray(BRAND_LIST_URL, $event, 'brands_wishlist', null, this.form_fields[1][1].fields),
              },
              afterList: $event => getPaginatedPropsForArray(BRAND_LIST_URL, '', 'brands_wishlist', $event, this.form_fields[1][1].fields),
              props: {
                options: [],
                searchable: false,
                label: 'caption',
                multiple: true,
                'internal-search': false,
                search: '',
                taggable: false,
                pagination: {},
                customLabel: obj => obj.props?.title,
                // placeholder: this._('Выберите продукт'),
              },
            },
          ],
        },
        {
          title: this._('Почему вы бы хотели представлять эти бренды как амбассадор TOTIS Pharma?'),
          fields: [
            {
              name: 'reason',
              label: this._('Ваш ответ'),
              rules: 'required',
              classname: 'is-textarea',
              tag: 'd-control-textarea',
              props: {
                rows: 6,
                class: 'is-textarea',
              },
            },
          ],
        },
      ]],
      form: {
        name: decodeHTMLEntities(`${window.request_name || ''} ${window.request_lastname || ''}`),
        phone: window.request_phone,
        city: '',
        comment: '',
        social_activity: '',
        specialization: '',
        reason: '',
        instagram: '',
        facebook: '',
        not_used_brands: false,
        brands_wishlist: [],
        brands_used: [],
      },
      summary: [
        {
          title: this._('Ваше ФИО'),
          value: 'name',
          cols: '12 6-xl',
        },
        {
          title: this._('Город, где вы проживаете'),
          value: 'city',
          cols: '12 6-xl',
        },
        {
          title: this._('Номер телефона'),
          value: 'phone',
          cols: '12 6-xl',
        },
        {
          title: this._('Специализация'),
          value: 'specialization',
          cols: '12 6-xl',
        },
        {
          title: this._('Ссылка на ваш Instagram аккаунт'),
          value: 'instagram',
          cols: '12 6-xl',
        },
        {
          title: this._('Ссылка на ваш Facebook аккаунт'),
          value: 'facebook',
          cols: '12 6-xl',
        },
        {
          title: this._('Как часто вы публикуете контент в соцсетях?'),
          value: 'social_activity',
          cols: '12',
        },
        {
          title: this._('Какие бренды TOTIS Pharma вы используете в работе?'),
          value: 'brands_used',
          getter: arr => arr.map(item => item.props.title).join(', '),
          cols: '12',
        },
        {
          title: this._('Какие бренды вы бы хотели представлять как амбассадор TOTIS Pharma?'),
          value: 'brands_wishlist',
          getter: arr => arr.map(item => item.props.title).join(', '),
          cols: '12',
        },
        {
          title: this._('Почему вы бы хотели представлять эти бренды как амбассадор TOTIS Pharma?'),
          value: 'reason',
          cols: '12',
        },
      ],
      non_field_errors: '',
    };
  },
  methods: {
    async prepareData(valid) {
      await this.$refs.validator.validate();
      if (!valid) return;
      const data = JSON.parse(JSON.stringify(this.form))
      console.log(this.form);
      data.brands_used = this.form.brands_used.map(item => item.id).filter(Boolean)
      data.brands_wishlist = this.form.brands_wishlist.map(item => item.id).filter(Boolean)
      if (this.form.brands_used.find(item => !item.id)) {
        data.not_used_brands = true
      }
      this.submit(valid, data);
    },
    async goNext(step) {
      if (this.$refs.validator) this.$refs.validator.reset()
      if (this.currentStep === step) return;
      if (this.currentStep > step) {
        this.currentStep = step
        return
      }
      const isValid = (await this.$refs.validator.validate())
      if (!isValid) return
      if (this.currentStep === this.steps.length) {
        this.prepareData(isValid)
        return
      }
      this.currentStep += 1
      if (this.$refs.validator) this.$refs.validator.reset()
    },
    send(data) {
      return ambassadorRequestCreateResource.execute({}, data).then(res => {
        AnswerMessage({ ...res.data, timeout: 3000 }, this);
        this.$refs.validator.$el.reset();
        this.$refs.validator.reset();
        this.form.city = ''
        this.form.comment = ''
        this.form.social_activity = ''
        this.form.specialization = ''
        this.form.reason = ''
        this.form.instagram = ''
        this.form.facebook = ''
        this.form.not_used_brands = false
        this.form.brands_wishlist = []
        this.form.brands_used = []
        this.$emit('close');
        this.currentStep = 1;
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
