import { createRouter } from '@/router'
import { mapState, mapActions } from 'vuex'

import { createI18n } from '@/i18n'
import { createStoreObject } from '@/store'
import { LangChangeResource } from '@api/vendor.service'
import LeaveModal from '@app/UiElements/LeaveModal'
import CountryLangChoice from '@app/UiElements/Header/CountryLangChoice.vue'

import '@/js/firebase/init'

import {
  wheelModalTrigger,
  setupGiftModal,
  cookieAgreementModal,
  openMobileAppsModal,
} from '@utils/modal-trigger'

import {
  getCookie,
  setCookie,
} from '@utils/cookies'

import {
  COUNTRY_CODE_COOKIE_NAME,
  UKRAINE_CODE,
} from '@app/consts'

export const TOTIS_2_AVAILABLE_WHEELS_CHECK_NAME = 'TOTIS_2_HAIR_AVAILABLE_WHEELS_CHECK'
export const TOTIS_2_COOKIE_AGGREMENT = 'TOTIS_2_HAIR:COOKIE_AGGREMENT'
export const TOTIS_2_MOBILE_APPS_TIME = 'TOTIS_2_HAIR:MOBILE_APPS_TIME_MODAL'
export const TOTIS_2_MOBILE_APPS_VIEW = 'TOTIS_2_HAIR:MOBILE_APPS_VIEW_MODAL'
export const TOTIS_2_NEW_COUNTRY_SELECTED = 'TOTIS_2_HAIR.new_country_selected_country_code'

const DIGITS_FOR_CURRENCY = {
  EUR: 'decimal_eur',
  UAH: 'decimal_uah',
  PLN: 'decimal_pln',
  CZK: 'decimal_czk',
}

const MOBILE_APP_TIMEOUT = 30000

export function createApp({ Vue, Router, Store, I18n }) {
  const router = createRouter({ Vue, Router })
  const i18n = createI18n({ Vue, I18n })
  const store = createStoreObject({ Vue, Store })
  Vue.prototype.$body = document.body
  const app = new Vue({
    i18n,
    router,
    store,
    delimiters: ['[[', ']]'],
    data() {
      return {
        True: true,
        False: false,
        None: undefined,
        slides: 4,
        show: false,
        date: new Date(),
        auth: window.authent,
        code: getCookie(COUNTRY_CODE_COOKIE_NAME),
        hasCount: true,
      }
    },
    computed: {
      ...mapState('search', [
        'show_overlay',
      ]),
      ...mapState('country', [
        'selectedCountry',
        'countriesList',
      ]),
    },
    async created() {
      const country = (await this.getCountries())
      const showCookieAgreement = getCookie(TOTIS_2_COOKIE_AGGREMENT)
      const newCountrySelected = getCookie(TOTIS_2_NEW_COUNTRY_SELECTED)
      const isWebView = new URLSearchParams(window.location.search).get('web_view')
      if (!showCookieAgreement && country.props.show_cookies_agreement && !window.is_bot) cookieAgreementModal(this)
      if (newCountrySelected && !isWebView) {
        if (country.props.children && country.props.children.length) {
          this.$modal.show(
            CountryLangChoice,
            {
              country,
              selectCountry: this.setNewCountry,
              changeCountry: this.changeCountry,
              canClose: false,
            }, {
              height: 'auto',
              classes: ['cart-modal'],
              adaptive: true,
              scrollable: true,
              clickToClose: false,
            }
          )
        }
      }
    },
    mounted() {
      // const show = getCookie(TOTIS_2_AVAILABLE_WHEELS_CHECK_NAME)
      const show = this.auth
      if (show) {
        wheelModalTrigger(this)
      }
      if (this.auth) {
        setupGiftModal(this)
        this.checkMobileAppsView()
      }
    },
    methods: {
      ...mapActions('search', [
        'trigger_overlay',
      ]),
      ...mapActions('country', [
        'getCountries', 'changeCountry', 'saveCountry',
      ]),
      setNewCountry(country) {
        this.saveCountry(country)
        this.changeCountry({ action: () => {} })
      },
      checkMobileAppsView() {
        if (this.code !== UKRAINE_CODE) return
        if (!window.show_mobile_apps_modal) return
        const showMobileAppsModal = getCookie(TOTIS_2_MOBILE_APPS_VIEW)
        const timestampCookie = getCookie(TOTIS_2_MOBILE_APPS_TIME)
        if (!timestampCookie) setCookie(TOTIS_2_MOBILE_APPS_TIME, (new Date()).getTime())
        if (showMobileAppsModal) return
        let int = null
        int = setInterval(() => {
          this.checkTimes(int)
        }, 2000)
      },
      checkTimes(interval) {
        const ZERO = 0
        if (navigator.maxTouchPoints > ZERO ||
          navigator.msMaxTouchPoints > ZERO) {
          const timestamp = (new Date()).getTime()
          const timestampCookie = getCookie(TOTIS_2_MOBILE_APPS_TIME)
          if ((timestamp - timestampCookie) > MOBILE_APP_TIMEOUT) {
            openMobileAppsModal(this)
            clearInterval(interval)
            return
          }
          return
        }
        clearInterval(interval)
      },
      async setLanguageFromNativeSelect(event) {
        const [selectedOption] = event.target.selectedOptions
        const { code, url } = selectedOption.dataset
        this.setLanguage(url, code)
        // await LangChangeResource.execute({ code })
        // // setCookie(TOTIS_2_SELECTED_LANGUAGE, code)
        // window.location.href = url
      },
      async setLanguage(url, code) {
        console.dir(url);
        await LangChangeResource.execute({ code })
        // setCookie(TOTIS_2_SELECTED_LANGUAGE, code)
        window.location.href = url
      },
      unchecked(id) {
        document.getElementById(id).checked = false
      },
      changeLocation(loc) {
        window.location.href = loc
      },
      onLeave() {
        this.$modal.show(LeaveModal, {

        }, {
          height: 'auto',
          name: this.name,
          minWidth: 300,
          adaptive: true,
          scrollable: true,
        })
      },
    },
  })
  function getCurrentDecimal(from, rule = DIGITS_FOR_CURRENCY[window.currency_code]) {
    let price = 0
    try {
      price = Number(from.replace(',', '.'))
    } catch (e) {
      price = from
    }
    return app.$n(price, rule || DIGITS_FOR_CURRENCY.EUR)
  }
  function getSplittedPrice(initialPrice, rule) {
    const price = getCurrentDecimal(initialPrice, rule)
    const [int, float] = price.split(',')
    return { int, float }
  }
  function getSplittedHtml(price, currency = window.currency, rule = DIGITS_FOR_CURRENCY[window.currency_code]) {
    const { int, float } = getSplittedPrice(price, rule)
    return `${int}${float ? ',' : ''}${float ? float : ''} ${currency}`
  }
  Vue.prototype.getCurrentDecimal = getCurrentDecimal
  Vue.prototype.getSplittedPrice = getSplittedPrice
  Vue.prototype.getSplittedHtml = getSplittedHtml
  window.app = app
  return { app, store, i18n, router }
}

export {
  createRouter,
}
