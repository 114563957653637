import BlogList from '@app/Blog/List'
import PreviewPost from '@app/Blog/Preview'
import PostFeedback from '@app/Blog/PostFeedback'
import PostFeedbackForm from '@app/Blog/PostFeedbackForm'

export default function install(Vue) {
  Vue.component('BlogList', BlogList)
  Vue.component('PreviewPost', PreviewPost)
  Vue.component('PostFeedback', PostFeedback)
  Vue.component('PostFeedbackForm', PostFeedbackForm)
}
