<template lang="pug">
  include /mixins
  +b.ds-panel--space_sm
    +e.element--offset_vertical
      +b.g-row--appearance_column
        +b.g-cell.g-cols--12.tt-space_pt--2-xl
          ui-accordion(
            :init='isDesktop'
            :is-calculate-height='false'
          )
            template(#default='{ activeState, toggleActive, collapse }')
              div
                +b.g-row--align_center.--justify_between.tt-space_mb--4-xl
                  +b.g-cell.g-cols(@click='isDesktop ? () => {} : toggleActive()',)
                    +b.ds-caption--size_md.P {{ _('Ваш заказ') }}
                      +b.ds-caption--size_md(v-if='!isDesktop')
                        +e.icon.mdi.I(
                          :class='[ activeState ? "mdi-chevron-up" : "mdi-chevron-down" ]'
                        )
                  +b.g-cell.g-cols
                    +b.tt-link--light.--variant_start.--appearance_underline.--styling_default.A(
                      :href='catalogUrl',
                    )
                      +e.title.P {{ _('Продолжить покупки') }}
                template(v-if='cart.quantity')
                  +b.ds-caption--size_2xs.--regular.--color_red(
                    v-if='!cartIsValid'
                  ) {{ _('В корзине есть товары, которых нет в необходимом Вам количестве') }}
                div(v-show='activeState')
                  template(
                    v-for='(products, idx) in cart.sales_groups'
                  )
                    cart-product(
                      v-for='(product, product_idx) in products'
                      :key='`${idx}_${product.id}`',
                      :product='product',
                      @update:cart='updateEntities($event)'
                    )
                      template(
                        #default='{ removeFromCart, updateCart, isProductload }'
                      )
                        div
                          //- pre {{idx}} {{product_idx}}
                          cart-campaign-ui(
                            :product='product',
                            :load='isProductload'
                            @removeFromCart='removeFromCart',
                            @updateCart='updateCart',
                            :show-gifts='!product_idx'
                          )
                  cart-product(
                    v-for='(product, idx) in cart.groups'
                    :key='idx',
                    :product='product',
                    @cart:update='$emit("cart:update")'
                  )
                    template(
                      #default='{ removeFromCart, updateCart, isProductload }'
                    )
                      cart-product-ui(
                        :product='product',
                        :load='isProductload'
                        @removeFromCart='removeFromCart',
                        @updateCart='updateCart',
                      )
                  cart-set-ui(
                    v-for='set, idx in cart.sets'
                    :key='`et_${idx}`',
                    :set='set',
                    :id='idx'
                    @update:cart='updateEntities($event)'
                  )
          +b.line-h--light
          +b.tt-space_pb--4.ROW(justify='end')
            
            cell(
              cols='12',
            )
              template(
                v-if='bonus && bonusInfo && bonusInfo.cashback_sum'
              )
                +b.tt-space_mt--4.ROW(
                  justify='between',
                  align='center',
                )
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--regular.P {{ _('Очікувана сума кешбеку') }}
                  cell(
                    cols='narrow'
                  )
                    row(align='center')
                      cell
                        +b.cashback-percent--only-icon
                          +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                            circle(cx='10' cy='10' r='10' fill='#FF7A00')
                            circle(cx='10' cy='10' r='8.5' fill='#FF7A00' stroke='white')
                            path(d='M10.5398 15C9.74432 15 9.00568 14.8816 8.32386 14.6448C7.65152 14.3989 7.06439 14.0528 6.5625 13.6066C6.07008 13.1603 5.68655 12.6321 5.41193 12.0219C5.13731 11.4117 5 10.7377 5 10C5 9.2623 5.13731 8.58834 5.41193 7.97814C5.68655 7.36794 6.07008 6.83971 6.5625 6.39344C7.06439 5.94718 7.65152 5.60565 8.32386 5.36885C9.00568 5.12295 9.74432 5 10.5398 5C11.5152 5 12.3769 5.16393 13.125 5.4918C13.8826 5.81967 14.5076 6.29326 15 6.91257L12.8835 8.72951C12.59 8.37432 12.2633 8.10109 11.9034 7.90984C11.553 7.70947 11.1553 7.60929 10.7102 7.60929C10.3598 7.60929 10.0426 7.66393 9.75852 7.77322C9.47443 7.88251 9.22822 8.04189 9.01989 8.25137C8.82102 8.46084 8.66477 8.71585 8.55114 9.01639C8.4375 9.30783 8.38068 9.6357 8.38068 10C8.38068 10.3643 8.4375 10.6967 8.55114 10.9973C8.66477 11.2887 8.82102 11.5392 9.01989 11.7486C9.22822 11.9581 9.47443 12.1175 9.75852 12.2268C10.0426 12.3361 10.3598 12.3907 10.7102 12.3907C11.1553 12.3907 11.553 12.2951 11.9034 12.1038C12.2633 11.9035 12.59 11.6257 12.8835 11.2705L15 13.0874C14.5076 13.6976 13.8826 14.1712 13.125 14.5082C12.3769 14.8361 11.5152 15 10.5398 15Z' fill='white')
                      cell
                        +b.ds-caption--size_sm.--regular.P(
                          style='line-height: 1;'
                          v-html='getSplittedHtml(bonusInfo.cashback_sum)'
                        )
              template(
                v-if='!bonus && cart.cart_bonus_sum'
              )
                +b.tt-space_mt--4.ROW(
                  justify='between',
                  align='center',
                )
                  cell(
                    cols='narrow'
                  )
                    +b.ds-caption--size_sm.--regular.P {{ _('Очікувана сума кешбеку') }}
                  cell(
                    cols='narrow'
                  )
                    row(align='center')
                      cell
                        +b.cashback-percent--only-icon
                          +e.icon.SVG(:title='_("cashback")' xmlns='http://www.w3.org/2000/svg', width='20', height='20', viewBox!='0 0 20 20', fill='none')
                            circle(cx='10' cy='10' r='10' fill='#FF7A00')
                            circle(cx='10' cy='10' r='8.5' fill='#FF7A00' stroke='white')
                            path(d='M10.5398 15C9.74432 15 9.00568 14.8816 8.32386 14.6448C7.65152 14.3989 7.06439 14.0528 6.5625 13.6066C6.07008 13.1603 5.68655 12.6321 5.41193 12.0219C5.13731 11.4117 5 10.7377 5 10C5 9.2623 5.13731 8.58834 5.41193 7.97814C5.68655 7.36794 6.07008 6.83971 6.5625 6.39344C7.06439 5.94718 7.65152 5.60565 8.32386 5.36885C9.00568 5.12295 9.74432 5 10.5398 5C11.5152 5 12.3769 5.16393 13.125 5.4918C13.8826 5.81967 14.5076 6.29326 15 6.91257L12.8835 8.72951C12.59 8.37432 12.2633 8.10109 11.9034 7.90984C11.553 7.70947 11.1553 7.60929 10.7102 7.60929C10.3598 7.60929 10.0426 7.66393 9.75852 7.77322C9.47443 7.88251 9.22822 8.04189 9.01989 8.25137C8.82102 8.46084 8.66477 8.71585 8.55114 9.01639C8.4375 9.30783 8.38068 9.6357 8.38068 10C8.38068 10.3643 8.4375 10.6967 8.55114 10.9973C8.66477 11.2887 8.82102 11.5392 9.01989 11.7486C9.22822 11.9581 9.47443 12.1175 9.75852 12.2268C10.0426 12.3361 10.3598 12.3907 10.7102 12.3907C11.1553 12.3907 11.553 12.2951 11.9034 12.1038C12.2633 11.9035 12.59 11.6257 12.8835 11.2705L15 13.0874C14.5076 13.6976 13.8826 14.1712 13.125 14.5082C12.3769 14.8361 11.5152 15 10.5398 15Z' fill='white')
                      cell
                        +b.ds-caption--size_sm.--regular.P(
                          style='line-height: 1;'
                          v-html='getSplittedHtml(cart.cart_bonus_sum)'
                        )
          +b.line-h--light
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(getTotalPrice)')
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            appearance='nowrap'
            v-if='shippingPrice'
          )
            cell(
              cols='8'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Стоимость доставки:') }} #[span.ds-caption--light {{ deliveryDescription }}]
            cell(
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(shippingPrice)')
          +b.line-h--light(
            v-if='cart.parameters && cart.parameters.promocode_title'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.promocode_title && cart.promocode_discount_summ'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по промокоду') }} {{ cart.parameters.promocode_title }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P(v-html='getSplittedHtml(cart.promocode_discount_summ)')
          +b.line-h--light(
            v-if='bonus'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='bonus'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Буде списано бонусів') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P -#[span(v-html='getSplittedHtml(bonus)') ]
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='balance'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Будет списано с баланса') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P -#[span(v-html='getSplittedHtml(balance)') ]
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка по акции:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P  {{ hasGlobalDiscountFromSum && hasGlobalDiscountFromSum.base.parameters.discount_amount }} %
          +b.line-h--light(
            v-if='cart.parameters && cart.parameters.site_discount'
          )
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between',
            align='center',
            v-if='cart.parameters && cart.parameters.site_discount'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_red.--regular.P {{ _('Скидка при покупке online:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_lg.--color_red.--regular.P  {{ cart.parameters.site_discount }} %
          +b.line-h--light(v-if='hasDiscount')
          //- pre {{ cart.parameters && cart.parameters.promocode_title }}
          //- pre  {{ cart.parameters && cart.parameters.site_discount }}
          //- pre {{ cart.parameters && cart.parameters.discount_from_sum_limit_action_by_items && hasGlobalDiscountFromSum }}
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            v-if='hasDiscount && !hasBonus || !hasDiscount && shippingPrice'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого к оплате:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(calcTotal(cart.total_price))')
          +b.tt-space_mt--5.tt-space_mb--5.ROW(
            justify='between'
            v-if='hasBonus || hasBalance'
          )
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--medium.P {{ _('Итого к оплате:') }}
            cell(
              cols='narrow'
            )
              +b.ds-caption--size_sm.--color_grey.--semibold.P(v-html='getSplittedHtml(calcTotal(totalSumWithBonus))')
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import CartProduct from '@app/Cart/Product'
import CartProductUi from '@app/Cart/ProductUi'
import CartSetUi from '@app/Cart/SetUi'
import { analyticInitCheckout } from '@analytic/checkout'

export default {
  name: 'OrderCart',
  props: {
    balance: {},
    bonus: {},
    bonusInfo: {},
    isFreeDelivery: {},
    shippingPrice: {},
    deliveryDescription: {},
  },
  components: {
    CartProduct,
    CartProductUi,
    CartSetUi,
  },
  data() {
    return {
      currency: window.currency,
      catalogUrl: window.baseUrl,
      isDesktop: false,
    }
  },
  computed: {
    ...mapState('cart', [
      'cart', 'coupon',
    ]),
    ...mapGetters('cart', [
      'cartIsValid',
    ]),
    hasGlobalDiscountFromSum() {
      return this.cart.groups.find(el => el.base.parameters.is_global_condition)
    },
    getTotalPrice() {
      return this.hasGlobalDiscountFromSum ||
        this.cart.parameters && this.cart.parameters.promocode_title && this.cart.promocode_discount_summ ? this.cart.total_price_without_discounts : this.cart.total_price_without_site_discount
    },
    hasDiscount() {
      return this.cart.parameters && this.cart.parameters.promocode_title && this.cart.promocode_discount_summ ||
        this.cart.parameters && this.cart.parameters.site_discount ||
        this.cart.parameters && this.cart.parameters.discount_from_sum_limit_action_by_items && this.hasGlobalDiscountFromSum
    },
    hasBonus() {
      return this.bonus
    },
    hasBalance() {
      return this.balance
    },
    totalSumWithBonus() {
      return this.bonusInfo.payment_sum
    },
  },
  watch: {
    'cart.quantity': {
      handler(nval) {
        if (!nval) window.location.href = window.indexPage
      },
    },
  },
  created() {
    this.calcDesktop()
  },
  async mounted() {
    await this.getCart()
    if (this.cart && this.cart.groups) {
      analyticInitCheckout(this.cart.groups)
    }
    window.addEventListener('resize', this.calcDesktop)
  },
  methods: {
    ...mapActions('cart', [
      'getCart',
    ]),
    calcTotal(price) {
      return price + this.shippingPrice
    },
    calcDesktop() {
      this.isDesktop = 1024 < window.innerWidth
    },
  },
}
</script>
