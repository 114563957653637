<template lang="pug">
include /mixins
+b.tt-loyalty-special-prices.relative
  ui-loader(v-if='isLoad')
  +e.brands
    +b.vacancy-tag-wrap.SPAN(
      v-for='brand in brands'
    )
      //- :class='{"is-active": isChecked(val.props.label)}'
      +e.element.INPUT(
        type='radio'
        :value='brand.id',
        name='brand'
        v-model='checked'
        :input-label='brand.props.title',
        :id='`brand:${brand.id}`'
      )
      //- @change='submit()'
      +b.LABEL.vacancy-tag(
        :for='`brand:${brand.id}`'
      ) {{ brand.props.title }}
  +e.table-wrap
    +e.table
      +b.tt-loyalty-special-prices-table.TABLE
        +e.thead.THEAD
          +e.tr--first.TR
            +e.th.TH.is-first {{ _('Продукт') }}
            +e.th.TH {{ _('Звичайна вартість') }}
            +e.th--column.TH(
              v-for='pack in packages'
              :style='{ "--bg-color": pack[0].props.pair_color }'
            )
              +e.data

                +b.tt-loyalty-mini-label
                  +e.icon.IMG(
                    :src='pack[0].props.img'
                  )
                  +e.title.P {{ pack[0].props.title }}
                p(style='white-space: nowrap') {{ _('від 1 од.') }}
            +e.th.TH {{ _('Спеціальна ціна для програми лояльності') }}
        +e.tbody.TBODY
          template(v-for='(productPack, id) in products' )

            +e.tr.TR(
              v-if='checked == id'
              v-for='(product, idx) in productPack'
            )
              +e.td.is-first.TD(
                :class='{ "is-bordered": idx + 1 != productPack.length }'
              )
                +e.link.A(
                  :href='`${product.props.url}?variant=${product.props.id}`'
                  target='_blank'
                )
                  span {{ product.props.product.props.title }}
                  span(v-if='!product.props.weight.props.color_number') &nbsp; {{ product.props.weight.props.name }}
              +e.td.TD(
                :class='{ "is-bordered": idx + 1 != productPack.length }'
                v-html='getSplittedHtml(product.props.price_definition.default_price.default_price, product.props.currency && product.props.currency.label, product.props.currency && product.props.currency.code)'
              )
              +e.td.TD(
                :class='{ "is-bordered": idx + 1 != productPack.length }'
                v-for='(pack, id) in packages'
                v-if='pack[0]'
                :style='{ "--bg-color": pack[0].props.pair_color }'
              )
                p(
                  v-html='getSplittedHtml(product.props.price_definition.loyalty_packages_prices[pack[0].id], product.props.currency && product.props.currency.label, product.props.currency && product.props.currency.code)'
                  v-if='product.props.price_definition.loyalty_packages_prices[pack[0].id]'
                )
                p(v-else) -
              +e.td.is-last.TD(
                v-html='getSplittedHtml(product.props.price_definition.special_price, product.props.currency && product.props.currency.label, product.props.currency && product.props.currency.code)'
                :class='{ "is-bordered": idx + 1 != productPack.length }'
              )
</template>

<script>

import {
  brandsListResponse,
  packagesListResponse,
  productsListResponse,
} from '@api/loyalty.service'

import {
  groupBy, groupByToArr,
  pathGetter,
} from '@utils/helpers'

export default {
  brandsListResponse,
  name: 'LoyaltySpecialPrices',
  data() {
    return {
      brands: [],
      packages: [],
      checked: [],
      products: {},
      isLoad: true,
    }
  },
  async created() {
    await this.getBrands()
    await this.getPackages()
    await this.getProducts()
    this.isLoad = false
  },
  methods: {
    async getBrands() {
      const { data } = (await brandsListResponse.execute())
      this.brands = data
      const [brand] = this.brands
      this.checked = brand.id
    },
    async getProducts() {
      const { data } = (await productsListResponse.execute())
      const groups = groupBy(data, pathGetter('props', 'product', 'props', 'brand', 'id'))
      this.products = groups
    },
    async getPackages() {
      const { data } = (await packagesListResponse.execute())
      const groups = groupByToArr(data, pathGetter('id'))
      this.packages = groups
    },
  },
}
</script>
