<template lang="pug">
include /mixins
+b.ambassador-request
  +b.VALIDATION-OBSERVER(
    ref="validator",
    v-slot="{ valid }",
    tag="form",
  )
    ui-loader(v-if='isLoad')
    div
      +b.form-fields
        +b.g-row
          +b.g-cell.g-cols.tt-space_mt--6(
            v-for='{ rules, name, label, tag, classname,events, errorClasses, mask, type, props, cols, getRules } in fields'
            :class='cols || "g-cols--12"'
          )
            validation-provider(
              v-slot="{ errors }"
              v-if='name == "without_url"'
              :name='name'
            )
              +b.d-control-input.--appearance_flexible.tt-space_mt--4
                +e.element.INPUT(
                  type='checkbox'
                  :name='name'
                  v-model='form[name]'
                  :id='name'
                )

                +e.LABEL.label--static(
                  :for='name'
                )
                  +b.ds-caption.--size_md.--color_black.P {{ label }}
            tag(
              v-else
              :tag='tag || "d-control-input"'
              :rules="rules || getRules && getRules()",
              :input-label='label'
              v-model='form[name]',
              :ref='name'
              v-bind='props'
              v-on='events'
              @input='$emit("update", form)'
              :name='name'
              :type='type || "text"'
              :key='name'
              :class='classname',
              :error-classes='errorClasses'
            )
      +b.ds-caption.--size_sm.--medium.--color_black.tt-space_mt--6.P {{ _('Нажимая кнопку “Отправить вы соглашаетесь с') }} &nbsp;
        +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='offer', target='_blank') {{ _('политикой конфиденциальности') }}
        span &nbsp; {{ _('и') }} &nbsp;
        +b.tt-link--inline.--styling_default.--black.A.ds-caption--size_sm.--medium(:href='rules', target='_blank') {{ _('правилами пользования TOTIS Pharma') }}
      +b.tt-space_mt--6
        +b.tt-button--auto.BUTTON(
          type='submit'
          :disabled='isLoad',
          @click.prevent='prepareData'
        )
          //- @click.prevent='prepareData(valid)'
          +e.title.P {{ _('Отправить заявку') }}

</template>
<script>
import { createEventRequest } from '@api/events.service';

import { AnswerMessage } from '@utils/submit';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

import { decodeHTMLEntities } from '@utils/helpers'
import {
  getPaginatedPropsForArray,
} from '@utils/valuesGetter'

import {
  BRAND_LIST_URL,
} from '@api/catalog.service'
import {
  marketplaceRequestCreateResource,
} from '@api/vendor.service'

export default {
  name: 'MarketplaceForm',
  mixins: [FormMixin],
  data() {
    return {
      isLoad: false,
      offer: window.offer,
      rules: window.rules,
      fields: [
        {
          name: 'title',
          label: this._('Название компании'),
          rules: 'required',
        },
        {
          name: 'url',
          label: this._('Адреса сайту'),
          getRules: () => ({ required: !this.form.without_url }),
          cols: 'g-cols--7',
        },
        {
          name: 'without_url',
          label: this._('Немає сайту магазину'),
          rules: 'required',
          cols: 'g-cols--5',
        },
        {
          name: 'position',
          label: this._('Должность'),
          rules: 'required',
        },
        {
          name: 'email',
          label: this._('Эл. почта'),
          rules: 'required',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
      ],
      form: {
        title: '',
        email: window.request_email || '',
        phone: window.request_phone,
        url: '',
        position: '',
        without_url: false,
      },
      non_field_errors: '',
    };
  },
  methods: {
    async prepareData(valid) {
      const isValid = (await this.$refs.validator.validate())
      console.log(isValid);
      if (!isValid) return
      const data = JSON.parse(JSON.stringify(this.form))
      this.submit(isValid, data);
    },
    send(data) {
      return marketplaceRequestCreateResource.execute({}, data).then(res => {
        AnswerMessage({ ...res.data, timeout: 3000 }, this);
        this.$refs.validator.$el.reset();
        this.$refs.validator.reset();
        this.form.title = ''
        this.form.url = ''
        this.form.position = ''
        this.form.without_url = false
        this.$emit('close');
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
