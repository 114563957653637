import {
  getSectorsList,
  getCurrentSector,
} from '@api/wheel.service'

import WheelWinModal from '@app/FortuneWheel/WheelWinModal'
import iFrameResize from 'iframe-resizer/js/iframeResizer.min.js'

// let tobtnScrollken
const zero = 0
function listener(event) {
  if ('string' === typeof event.data >= zero) {
    let data = event.data.split('[iFrameSizer]message:')[1]
    let token = ''
    data = JSON.parse(data)
    if (data && 'token' === data.type) {
      token = data.data?.token
    }
    return token
  }
  return null
}

if (window.addEventListener) {
  window.addEventListener('message', listener)
} else {
  // IE8
  window.attachEvent('onmessage', listener)
}

export function fortuneWheel() {
  // создаём переменные для быстрого доступа ко всем объектам на странице — блоку в целом, колесу, кнопке и язычку
  const [wheel] = document.getElementsByClassName('deal-wheel')
  if (!wheel) return
  iFrameResize({
    log: false,
    heightCalculationMethod: 'lowestElement',
  })
  // данные колеса
  let wheelData = ''
  // id нужного сектора
  let currentSector = ''
  // надписи и цвета на секторах
  const colors = {
    first: '#fdf9f4',
    second: '#821D76',
  }
  let prizes = []
  const [wheelWrapper] = document.getElementsByClassName('fortune-wheel')
  const wheelId = parseInt(wheel.dataset.wheelId)
  const [spinQuantityText] = document.getElementsByClassName('js-spin-quantity')
  const [wheelLogo] = document.getElementsByClassName('js-wheel-logo')
  const [spinner] = wheel.getElementsByClassName('spinner')
  const triggers = [...document.getElementsByClassName('btn-spin')]
  const [ticker] = wheel.getElementsByClassName('ticker')
  const spineds = [...document.getElementsByClassName('already-spined-text')]
  const spinClass = 'is-spinning'
  // получаем все значения параметров стилей у секторов
  const spinnerStyles = window.getComputedStyle(spinner)

  // прописываем CSS-классы, которые будем добавлять и убирать из стилей
  let selectedClass = ''
  // на сколько секторов нарезаем круг
  let prizeSlice = ''
  // на какое расстояние смещаем сектора друг относительно друга
  let prizeOffset = ''
  // угол смещения нужного элемента
  let targetAngle = 0
  // переменная для анимации
  let tickerAnim
  // угол вращения
  let rotation = 0
  // текущий сектор
  const currentSlice = 0
  // переменная для текстовых подписей
  let prizeNodes

  // расставляем текст по секторам
  const createPrizeNodes = () => {
    // обрабатываем каждую подпись
    prizes.forEach(({ color_text, title, id }, i) => {
      // каждой из них назначаем свой угол поворота
      const rotation = ((prizeSlice * i) * -1) - prizeOffset
      // добавляем код с размещением текста на страницу в конец блока spinner
      spinner.insertAdjacentHTML(
        'beforeend',
        // текст при этом уже оформлен нужными стилями
        `<li class="prize" data-id="${id}" data-index="${i}" style="--rotate: ${rotation}deg">
          <span style=" color: ${color_text}" class="text">${title}</span>
        </li>`
      )
    })
  }

  // рисуем разноцветные секторы
  const createConicGradient = () => {
    // устанавливаем нужное значение стиля у элемента spinner
    spinner.setAttribute(
      'style',
      `background: conic-gradient(
        from -90deg,
        ${prizes
      // получаем цвет текущего сектора
        .map(({ color }, i) => `${color} ${(((100 / prizes.length) * (prizes.length - i) - (100 / prizes.length))) + 0.1}% ${(100 / prizes.length) * (prizes.length - i)}%`)
        .reverse()
        }
      )`
    )
  }

  // Проверяем есть ли доступные вращения
  function checkTwistCount(count) {
    if (0 === count) {
      triggers.forEach(trigger => trigger.classList.add('btn-spin-disabled'))
      spineds.forEach(spined => spined.classList.remove('fortune-wheel__subtitle-disabled'))
    }
  }
  // создаём функцию, которая нарисует колесо в сборе
  async function setupWheel() {
    await getSectorsList.execute({ id: wheelId }).then(({ data: { props: data } }) => {
      checkTwistCount(data.twist_count)
      wheelData = data
      prizes = data.fortunes_sections
      spinQuantityText.innerHTML = `${data.twist_count} ${data.word}`
      if (data.logo) {
        wheelLogo.classList.remove('logo-disable')
        wheelLogo.src = data.logo
      }
      if (data.img?.full_size) {
        wheelWrapper.style.backgroundImage = `url('${data.img.full_size}')`
        wheelWrapper.style.backgroundSize = 'cover'
        wheelWrapper.style.backgroundPosition = 'center'
      } else {
        wheelWrapper.style.backgroundColor = data.background_color
      }
    })
    prizeSlice = 360 / prizes.length
    prizeOffset = Math.floor(180 / prizes.length)
    // сначала секторы
    createConicGradient()
    // потом текст
    createPrizeNodes()
    // а потом мы получим список всех призов на странице, чтобы работать с ними как с объектами
    prizeNodes = wheel.getElementsByClassName('prize')
  }

  // определяем количество оборотов, которое сделает наше колесо
  // const spinertia = (min, max) => {
  //   min = Math.ceil(min)
  //   max = Math.floor(max)
  //   return Math.floor(min)
  // }

  // функция запуска вращения с плавной остановкой
  const runTickerAnimation = () => {
    // взяли код анимации отсюда: https://css-tricks.com/get-value-of-css-rotation-through-javascript/
    const values = spinnerStyles.transform.split('(')[1].split(')')[0].split(',')
    const a = values[0]
    const b = values[1]
    const rad = Math.atan2(b, a)

    // if (rad < 0) rad += (2 * Math.PI)

    // const angle = Math.round(rad * (180 / Math.PI))
    // const slice = Math.floor(angle / prizeSlice)

    // анимация язычка, когда его задевает колесо при вращении
    // если появился новый сектор
    // if (currentSlice !== slice) {
    //   // убираем анимацию язычка
    //   ticker.style.animation = "none"
    //   // и через 10 миллисекунд отменяем это, чтобы он вернулся в первоначальное положение
    //   setTimeout(() => ticker.style.animation = null, 10)
    //   // после того, как язычок прошёл сектор - делаем его текущим
    //   currentSlice = slice
    // }
    // // запускаем анимацию
    // tickerAnim = requestAnimationFrame(runTickerAnimation)
  }

  // функция выбора призового сектора
  const selectPrize = () => {
    // если id секторов c сервера будут идти по порядку
    // const selected = currentSector - 1
    // selectedClass = selected % 2 ? 'selected' : 'selected-dark'
    // prizeNodes[selected].classList.add(selectedClass)

    // выбор сектора по индексу
    Array.from(prizeNodes).forEach(el => {
      if (parseInt(el.dataset.id, 10) === currentSector.id) {
        const selected = el.dataset.index
        selectedClass = selected % 2 ? 'selected' : 'selected-dark'
        el.classList.add(selectedClass)
        setTimeout(() => {
          wheelWrapper.classList.add('js-start-animate')
          window.app.__vue__.$modal.show(WheelWinModal,
            {
              info: currentSector,
            },
            {
              height: 'auto',
              minWidth: 300,
              adaptive: true,
              scrollable: true,
              classes: ['videoPopup'],
            })
        }, 1000)
      }
    })
  }

  // отслеживаем нажатие на кнопку
  triggers.forEach(trigger => {
    trigger.addEventListener('click', async () => {
      // получаем нужный сектор
      wheelWrapper.classList.remove('js-start-animate')
      await getCurrentSector.execute({ id: wheelData.id }).then(res => {
        if (200 === res.code) {
          currentSector = res.data
          spinQuantityText.innerHTML = `${res.data.twist_count} ${res.data.word}`
          checkTwistCount(res.data.twist_count)
        }
      })
      prizes.forEach(({ id }, i) => {
        // каждой из них назначаем свой угол поворота
        const rotation = ((prizeSlice * i) * -1) - prizeOffset
        // задаем нужный сектор
        if (currentSector.id === id) {
          targetAngle = rotation
        }
      })
      // делаем её недоступной для нажатия
      trigger.disabled = true
      // выбираем рандомное место на нужном секторе
      function getRandomIntInclusive(min, max) {
        min = Math.ceil((min / 2) + 2)
        max = Math.floor((max / 2) - 2)
        return Math.floor(Math.random() * (max - min + 1) + min) // The maximum is inclusive and the minimum is inclusive
      }
      // задаём вращение до нужного сектора
      rotation = (Math.floor((Math.random() + 1) * 4) * 360 + 90) + (Math.abs(targetAngle) + getRandomIntInclusive(prizeSlice * -1, prizeSlice))
      // убираем прошлый приз
      Array.from(prizeNodes).forEach(prize => (selectedClass ? prize.classList.remove(selectedClass) : () => {}))
      // добавляем колесу класс is-spinning, с помощью которого реализуем нужную отрисовку
      wheel.classList.add(spinClass)
      // через CSS говорим секторам, как им повернуться
      spinner.style.setProperty('--rotate', rotation)
      // возвращаем язычок в горизонтальную позицию
      ticker.style.animation = 'none'
      // запускаем анимацию вращение
      runTickerAnimation()
    })
  })

  // отслеживаем, когда закончилась анимация вращения колеса
  spinner.addEventListener('transitionend', () => {
    // останавливаем отрисовку вращения
    // cancelAnimationFrame(tickerAnim)
    // получаем текущее значение поворота колеса
    rotation %= 360
    // выбираем приз
    selectPrize()
    // убираем класс, который отвечает за вращение
    wheel.classList.remove(spinClass)
    // отправляем в CSS новое положение поворота колеса
    spinner.style.setProperty('--rotate', rotation)
    // делаем кнопку снова активной
    triggers.forEach(trigger => trigger.disabled = false)
  })

  // подготавливаем всё к первому запуску

  setupWheel()
}
