<template lang="pug">
include /mixins
//- +b.trip-state.tt-space_mb--4
//-   +e.icon
//-     svg(width='24' height='24' viewbox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg')
//-       path(d='M11.1336 3.5C11.5185 2.83333 12.4808 2.83333 12.8657 3.5L21.5259 18.5C21.9108 19.1667 21.4297 20 20.6599 20H3.33938C2.56958 20 2.08846 19.1667 2.47336 18.5L11.1336 3.5Z' stroke='#FF7A00' stroke-width='1.3')
//-       path(d='M11.2466 14.0266C11.2466 14.3923 11.543 14.6897 11.9087 14.6897C12.2754 14.6897 12.5718 14.3933 12.5718 14.0275L12.5718 7.63816C12.5653 7.46677 12.4927 7.30455 12.3691 7.18558C12.2456 7.06662 12.0807 7.00016 11.9092 7.00016C11.7377 7.00016 11.5728 7.06662 11.4493 7.18558C11.3257 7.30455 11.2531 7.46677 11.2466 7.63816L11.2466 14.0266Z' fill='#FF7A00')
//-       path(d='M11.2574 17.874C11.4231 18.0397 11.6478 18.1328 11.8821 18.1328C12.1164 18.1328 12.3412 18.0397 12.5069 17.874C12.6725 17.7084 12.7656 17.4836 12.7656 17.2493C12.7656 17.015 12.6725 16.7903 12.5069 16.6246C12.3412 16.4589 12.1164 16.3658 11.8821 16.3658C11.6478 16.3658 11.4231 16.4589 11.2574 16.6246C11.0917 16.7903 10.9986 17.015 10.9986 17.2493C10.9986 17.4836 11.0917 17.7084 11.2574 17.874Z' fill='#FF7A00')
//-   +b.ds-caption--size_2md.--medium.P: +trans С текущей динамикой закупок, ваша бронь на поездку может быть отменена!
+b.trip-distribution-message(v-if='trip.conditions')
  +e.img.IMG(
    :src='`/static/img/totik/${message.img}.png`',
  )
  +e.content
    +e.title.P {{ message.title }}
    +e.text.P {{ message.text }} &nbsp;
      span(v-if='message.hasSum' v-html='getSplittedHtml(message.sum(), "€")')
    +e.after_text.P(
      :style="{ '--color': message.color }"
    ) {{ message.after_text }} &nbsp;
      span(v-if='message.hasAfterSum' v-html='getSplittedHtml(message.afterSum(), "€")')
  //- pre {{hasDebt}} {{getDebt()}} {{getOldDebt()}} {{getActual()}}
  //- pre {{isOk}}
  //- pre {{isPerfect}}
</template>

<script>

import {
  mapState, mapActions,
} from 'vuex'

import pathOr from 'ramda/src/pathOr'

const FUTURE_MONTH_STATUS = 'future'
const CURRENT_MONTH_STATUS = 'current'
const PAST_MONTH_STATUS = 'past'

const ZERO = 0

const HAS_DEPT = 'bad'
const IS_OK = 'good'
const IS_PERFECT = 'perfect'

export default {
  props: {
    trip_label: {
      type: String,
      required: true,
    },
    // path: {
    //   type: Array,
    //   required: true,
    // },
  },
  data() {
    return {
      messages: {
        [HAS_DEPT]: {
          title: this._('Треба трохи піднажати!'),
          text: this._('Для того, щоб виконати умови цього місяця Вам треба докупити ще на'),
          after_text: this._('включно з боргом'),
          img: HAS_DEPT,
          hasSum: true,
          hasAfterSum: true,
          sum: () => this.getDebt(),
          afterSum: () => this.getOldDebt(),
          color: 'var(--theme-secondary-orange)',
        },
        [IS_OK]: {
          title: this._('Так тримати!'),
          text: this._('Для того щоб виконати умови цього місяця Вам треба докупити ще на'),
          img: IS_OK,
          hasSum: true,
          hasAfterSum: false,
          sum: () => this.getActual(),
        },
        [IS_PERFECT]: {
          title: this._('Оце темп, вітаю!'),
          text: this._('Ви повністю виконали умови цього місяця і навіть більше!'),
          after_text: this._('На наступний місяць ви вже проплатили '),
          img: IS_PERFECT,
          hasSum: false,
          hasAfterSum: true,
          color: 'var(--theme-primary-green)',
          afterSum: () => this.getFuture(),
        },
      },
    };
  },
  computed: {
    ...mapState('trip', ['trip']),
    list() {
      return pathOr({}, this.path, this.trip);
    },
    hasDebt() {
      if (!this.trip.conditions) return false;
      return !!this.trip.conditions.find(d => d.props.monthly_distribution.find(e => e.status === PAST_MONTH_STATUS && e.balance < ZERO))
    },
    isOk() {
      if (!this.trip.conditions) return false;
      return !!this.trip.conditions.find(d => d.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS && e.balance < ZERO))
    },
    isPerfect() {
      if (!this.trip.conditions) return false;
      return this.trip.conditions.every(d => d.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS && e.balance === ZERO))
    },
    message() {
      // eslint-disable-next-line no-nested-ternary
      const val = this.isPerfect ? IS_PERFECT : (this.isOk && !this.hasDebt ? IS_OK : HAS_DEPT)
      console.log(val, '====----');
      return this.messages[val]
    },
  },
  mounted() {
    this.getTrip(this.trip_label);
  },
  methods: {
    ...mapActions('trip', ['getTrip']),
    getDebt() {
      // const debt = Math.abs(this.trip.conditions
      //   .reduce((acc, curr) => acc + curr.props.monthly_distribution.findLast(e => e.status === PAST_MONTH_STATUS).balance, 0))
      
      const debt = Math.abs(this.trip.conditions
        .reduce((acc, curr) => {
          let current = curr.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS)
          if (!current) {
            current = curr.props.monthly_distribution.findLast(e => e.status === PAST_MONTH_STATUS)
          }
          if ((Math.abs(current.balance) - current.monthly_purchase) > ZERO) {
            acc += current.balance
          }
          return acc
        }, 0))
      // const debt = Math.abs(this.trip.conditions
      //   .reduce((acc, curr) => acc + curr.props.monthly_distribution
      //     .reduce((acc2, curr2) => {
      //       if (curr2.status === PAST_MONTH_STATUS) {
      //         acc2 += curr2.balance
      //       }
      //       return acc2
      //     }, 0), 0))
      const alreadyPaid = Math.abs(this.trip.conditions
        .reduce((acc, curr) => {
          let current = curr.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS)
          if (!current) {
            current = curr.props.monthly_distribution.findLast(e => e.status === PAST_MONTH_STATUS)
          }
          // if ((Math.abs(current.balance) - current.monthly_purchase) > ZERO) {
          // }
          acc += current.monthly_purchase
          return acc
        }, 0))
      // const alreadyPaid = Math.abs(this.trip.conditions
      //   .reduce((acc, curr) => acc + curr.props.monthly_distribution
      //     .reduce((acc2, curr2) => {
      //       if (curr2.status === CURRENT_MONTH_STATUS) {
      //         acc2 += curr2.monthly_purchase
      //       }
      //       return acc2
      //     }, 0), 0))
      
      return debt
    },
    getOldDebt() {
      const alreadyPaid = Math.abs(this.trip.conditions
        .reduce((acc, curr) => {
          let current = curr.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS)
          if (!current) {
            current = curr.props.monthly_distribution.findLast(e => e.status === PAST_MONTH_STATUS)
          }
          // if ((Math.abs(current.balance) - current.monthly_purchase) > ZERO) {
          // }
          acc += current.monthly_purchase
          return acc
        }, 0))
      return alreadyPaid
    },
    getActual() {
      const alreadyPaid = Math.abs(this.trip.conditions
        .reduce((acc, curr) => {
          let current = curr.props.monthly_distribution.find(e => e.status === CURRENT_MONTH_STATUS)
          if (!current) {
            current = curr.props.monthly_distribution.findLast(e => e.status === PAST_MONTH_STATUS)
          }
          if (current.monthly_purchase > Math.abs(current.balance)) {
            acc += current.monthly_purchase - Math.abs(current.balance)
          }
          return acc
        }, 0))
      return alreadyPaid
    },
    getFuture() {
      const alreadyPaid = Math.abs(this.trip.conditions
        .reduce((acc, curr) => {
          let current = curr.props.monthly_distribution.find(e => e.status === FUTURE_MONTH_STATUS)
          if (!current) {
            current = curr.props.monthly_distribution.findLast(e => e.status === CURRENT_MONTH_STATUS)
          }
          if (current.monthly_purchase > Math.abs(current.balance)) {
            acc += current.monthly_purchase - Math.abs(current.balance)
          }
          return acc
        }, 0))
      return alreadyPaid
    },
  },
}

</script>
