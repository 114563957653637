var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ds-panel ds-panel--space_2xl"},[_c('div',{staticClass:"ds-panel__element ds-panel__element--offset_full"},[_c('validation-observer',{ref:"validator",attrs:{"tag":"form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [(_vm.isLoad)?_c('ui-loader'):_vm._e(),_c('div',[_c('div',{staticClass:"form-fields"},[_c('p',{staticClass:"ds-caption ds-caption--size_lg ds-caption--size_xl-xl ds-caption--medium ds-caption--color_grey"},[_vm._v(_vm._s(_vm.info.title))]),_c('div',{staticClass:"g-row"},_vm._l((_vm.fields),function(ref){
var rules = ref.rules;
var name = ref.name;
var label = ref.label;
var tag = ref.tag;
var classname = ref.classname;
var events = ref.events;
var errorClasses = ref.errorClasses;
var mask = ref.mask;
var type = ref.type;
var props = ref.props;
var cols = ref.cols;
var getRules = ref.getRules;
return _c('div',{staticClass:"g-cell g-cols tt-space_mt tt-space_mt--6",class:cols || "g-cols--12"},[_c('tag',_vm._g(_vm._b({key:name,ref:name,refInFor:true,class:classname,attrs:{"tag":tag || "d-control-input","rules":rules || getRules && getRules(),"input-label":label,"name":name,"type":type || "text","error-classes":errorClasses},on:{"input":function($event){return _vm.$emit("update", _vm.form)}},model:{value:(_vm.form[name]),callback:function ($$v) {_vm.$set(_vm.form, name, $$v)},expression:"form[name]"}},'tag',props,false),events))],1)}),0)]),_c('div',{staticClass:"tt-space_mt tt-space_mt--6"},[_c('button',{staticClass:"tt-button",attrs:{"type":"submit","disabled":_vm.isLoad},on:{"click":function($event){$event.preventDefault();return _vm.prepareData($event)}}},[_c('p',{staticClass:"tt-button__title"},[_vm._v(_vm._s(_vm._('Отправить')))])])])])]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }