<template lang="pug">
include /mixins
+b.ds-panel--space_2xl
  +e.element--offset_full
    +b.VALIDATION-OBSERVER(
      ref="validator",
      v-slot="{ valid }",
      tag="form",
    )
      ui-loader(v-if='isLoad')
      div
        +b.form-fields
          +b.ds-caption.--size_lg.--size_xl-xl.--medium.--color_grey.P {{ info.title }}
          +b.g-row
            +b.g-cell.g-cols.tt-space_mt--6(
              v-for='{ rules, name, label, tag, classname,events, errorClasses, mask, type, props, cols, getRules } in fields'
              :class='cols || "g-cols--12"'
            )
              tag(
                :tag='tag || "d-control-input"'
                :rules="rules || getRules && getRules()",
                :input-label='label'
                v-model='form[name]',
                :ref='name'
                v-bind='props'
                v-on='events'
                @input='$emit("update", form)'
                :name='name'
                :type='type || "text"'
                :key='name'
                :class='classname',
                :error-classes='errorClasses'
              )
        +b.tt-space_mt--6
          +b.tt-button.BUTTON(
            type='submit'
            :disabled='isLoad',
            @click.prevent='prepareData'
          )
            //- @click.prevent='prepareData(valid)'
            +e.title.P {{ _('Отправить') }}

</template>
<script>

import { AnswerMessage } from '@utils/submit';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';

import {
  createFeedback,
} from '@api/feedback.service'

import {
  marketplaceRequestCreateResource,
} from '@api/vendor.service'

export default {
  name: 'PostFeedbackForm',
  mixins: [FormMixin],
  props: {
    info: {
      type: Object,
    },
  },
  data() {
    return {
      isLoad: false,
      offer: window.offer,
      rules: window.rules,
      fields: [
        {
          name: 'name',
          label: this._('Имя'),
          rules: 'required',
        },
        {
          name: 'last_name',
          label: this._('Фамилия'),
          rules: 'required',
        },
        {
          name: 'phone',
          label: this._('Номер телефона'),
          rules: 'required|phone',
          tag: 'phone-input',
        },
        {
          name: 'email',
          label: this._('Эл. почта'),
          rules: 'required',
        },
        {
          name: 'message',
          label: this._('Ваш ответ'),
          classname: 'is-textarea',
          tag: 'd-control-textarea',
          props: {
            rows: 6,
            class: 'is-textarea',
          },
        },
      ],
      form: {
        name: window.request_name,
        last_name: window.request_lastname,
        email: window.request_email || '',
        phone: window.request_phone,
        message: '',
        source: window.location.href,
      },
      non_field_errors: '',
    };
  },
  methods: {
    async prepareData(valid) {
      const isValid = (await this.$refs.validator.validate())
      console.log(isValid);
      if (!isValid) return
      const data = JSON.parse(JSON.stringify(this.form))
      data.subject = this.info.subject
      console.log(data, this.info);
      this.submit(isValid, data);
    },
    send(data) {
      return createFeedback.execute({}, data).then(res => {
        const meta = {
          message: this.info.message,
          timeout: 3000,
        }
        console.log(meta);
        AnswerMessage(meta, this);
        this.$refs.validator.$el.reset();
        this.$refs.validator.reset();
        this.form.title = ''
        this.form.url = ''
        this.form.position = ''
        this.form.without_url = false
        if (this.info.pr) {
          this.info.pr(true)
        }
        this.$emit('close');
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
  },
};
</script>
