<template lang="pug">
  include /mixins

  +b.control-stack.VALIDATION-OBSERVER.bordered-form(
    ref="validator",
    v-slot="{ valid, errors }",
    tag="form",
    autocomplete='off'
  )
    div
      ui-loader(v-if='isLoad')
      +b.ds-caption--size_md.tt-space_mb--1.P {{ _('На вашем счету') }} #[strong(v-html="getSplittedHtml(pathOr(0, ['props', 'country_balance', 'props', 'balance'], externalUser))")]
      //- +b.ds-caption--size_xs.P(
        //- v-if='!showDescription'
      //- ) {{ _('Максимальное количество бонусов, которое вы можете использовать для этого заказа: ') }} #[strong(v-html='getSplittedHtml(info && info.order_available_bonus  || 0)')]
      .relative(v-if='balance && errors.balance_sum && errors.balance_sum.length ')
        +b.error-label.LABEL() {{ errors.balance_sum[0] }}
      +b.tt-space_mt--5
        row(
          space='none',
          appearance='nowrap'
          v-if='balance'
        )
          cell.full-w(cols='')
            +b.d-control-input--appearance_bordered.--appearance_stacked.control-stack__element.control-stack__element--position_first()
              +e.element(name='coupon' value='test' disabled='true' readonly='readonly' rules='required') {{ Number(balance).toFixed(2) }}
              label.d-control-input__label--active.d-control-input__label.d-control-input__label--bound(for='coupon') {{ _("Сумма с баланса к списанию:") }}

          cell(cols='narrow')
            +b.tt-button--for-input.--appearance_free.d-control-button.control-stack__element.control-stack__element--position_last.BUTTON(
                type='submit',
                @click.prevent='removePromo()'
              )
                +e.title.P.mdi.mdi-close
        row(
          space='none',
          appearance='nowrap'
          v-show='!balance'
        )
          cell.full-w(cols='')
            +b.d-control-input--appearance_bordered.--appearance_stacked.D-CONTROL-INPUT.control-stack__element.control-stack__element--position_first(
              :input-label='_("Сумма баланса")'
              v-model='form.balance_sum'
              id='balance_sum',
              ref='balance_sum',
              name='balance_sum',
              type='number'
              :rules='{ max_value_bonuses: userBalance }'
              :max='userBalance'
              @input='setMax'
            )
            .relative(v-if="non_field_errors")
              +b.error-label.LABEL {{ non_field_errors[0] }}
          cell(cols='narrow')
            +b.tt-button--for-input.--appearance_free.d-control-button.control-stack__element.control-stack__element--position_last.LOADER-BTN(
              type='submit',
              :disabled='isLoad || !form.balance_sum',
              :action='() => prepareData(valid)'
              :title="_('Применить')"
              :load='isLoad'
            )

</template>

<script>
import pathOr from 'ramda/src/pathOr';
import { applyBonuses } from '@api/order.service';
import { updateUserData } from '@api/staff.service';

import FormMixin, { defaultValidatorErrorsParser } from '@app/Forms/FormMixin';
import { mapState, mapActions, mapMutations } from 'vuex';

import { confirmWithPromise } from '@utils/helpers';

import { getCookie } from '@utils/cookies';

import { COUNTRY_CODE_COOKIE_NAME } from '@app/consts';

const MAX_PERCENT = '100.00';

export default {
  name: 'Balance',
  mixins: [FormMixin],
  props: ['phone', 'balance', 'cartChange', 'shippingService', 'bonus'],
  data() {
    return {
      form: {
        balance_sum: '',
      },
      isLoad: false,
      non_field_errors: '',
      type: window.coupon,
      labels: {
        promo_exist: this._('ИСПОЛЬЗОВАН ПРОМО-КОД: '),
        promo: this._('Введіть промокод (якщо є)'),
      },
      info: {},
      externalUser: {},
      balance_value: null,
      currency: window.currency,
    };
  },
  watch: {
    shippingService: {
      handler() {
        this.send({ balance_sum: 0 });
      },
    },
    cartChange: {
      async handler() {
        console.log(this.cartChange, '----------CART CHANGE -------------');
        let data = (await this.prepareData(true, { balance_sum: this.balance || 0 }))
        if (data.balance_sum) {
          // if (data.balance_sum > data.order_available_bonus) {
          //   data = (await this.prepareData(true, { balance_sum: data.order_available_bonus || 0 }))
          // }
          this.form.balance_sum = Number(data.balance_sum.toFixed(2))
          this.balance_value = data.balance_sum
        }
        if (this.form.balance_sum) {
          console.log(data, '****');
        }
        // this.send({ balance_sum: this.balance });
      },
    },
  },
  computed: {
    ...mapState('cart', ['cart', 'coupon']),
    ...mapState('staff', ['user']),

    userBalance() {
      return Number(Math.min(
        pathOr(0, ['order_available_balance'], this.info),
        pathOr(0, ['props', 'country_balance', 'props', 'balance'], this.externalUser)
      ).toFixed(2));
    },
    // showDescription() {
    //   const maxPercent = pathOr(
    //     0,
    //     [
    //       'props',
    //       'country_discount_package',
    //       'props',
    //       'discount_package',
    //       'props',
    //       'max_percent_bonuses_for_payment',
    //     ],
    //     this.user
    //   )
    //   const countyMax = window.country_max_percent_bonuses_for_payment
    //   return maxPercent === MAX_PERCENT || countyMax === MAX_PERCENT;
    // },
  },
  created() {
    updateUserData.execute().then(({ data }) => {
      this.externalUser = data;
    });
  },
  mounted() {
    this.send({ balance_sum: 0 });
  },
  methods: {
    ...mapMutations('cart', ['SET_CART']),
    ...mapActions('cart', ['changeCart', 'getCart']),
    pathOr,
    setMax(event) {
      if (event > this.userBalance) {
        this.form.balance_sum = Number(this.userBalance.toFixed(2))
        this.balance_value = this.userBalance
        return
      }
      this.balance_value = event
    },
    async prepareData(valid, form) {
      await this.$refs.validator.validate();
      if (!valid) return;
      return this.submit(valid, form || { balance_sum: this.balance_value });
    },
    send(data) {
      return applyBonuses.execute({}, { ...data, bonus_sum: this.bonus }).then(({ data: response }) => {
        this.info = response;
        this.$emit('input', response);
        this.$refs.validator.reset();
        return this.info
      });
    },
    updateValidator(errors) {
      const parsed = defaultValidatorErrorsParser(errors);
      this.non_field_errors = parsed.non_field_errors;
      this.$refs.validator.setErrors(parsed);
    },
    async removePromo() {
      const question = this._('Отменить списание средств с баланса?');

      await confirmWithPromise(question, this);
      this.send({ balance_sum: 0 });
    },
  },
};
</script>
