import Condition from '@app/Trips/Condition'
import MonthlyDistribution from '@app/Trips/MonthlyDistribution'
import ContractRequest from '@app/Trips/ContractRequest'
import State from '@app/Trips/State'

export default function install(Vue, prefix = 'trip-') {
  Vue.component(`${prefix}condition`, Condition)
  Vue.component(`${prefix}monthly-distribution`, MonthlyDistribution)
  Vue.component(`${prefix}contract-request`, ContractRequest)
  Vue.component(`${prefix}state`, State)
}
