<template lang="pug">
include /mixins
div()

  slot(
    :is-signed='contractSign'
    :contract='contract'
    :is-rejected='contractReject'
    :action='action'
    :trip_potential_participant='trip_potential_participant'
    :isContractRejected='isContractRejected'
    :isContractFullyRejected='isContractFullyRejected'
    :isContractCanBeSigned='isContractCanBeSigned'
    :isContractCanBeRejected='isContractCanBeRejected'
    :isContractWaitToSigned='isContractWaitToSigned'
    :isActual='isActual'

  )
</template>

<script>

import {
  mapState, mapActions,
} from 'vuex'

import pathOr from 'ramda/src/pathOr'
import { confirmWithPromise } from '@utils/helpers'

import {
  tripParticipantCreateResource,
  tripParticipantUpdateResource,
} from '@api/vendor.service'

const ARCHIVED_STATE = 'archived'
const ACTUAL_STATE = 'actual'

export default {
  props: {
    trip_id: {
      type: String,
      required: true,
    },
    plan_id: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('trip', ['trip']),
    list() {
      return pathOr({}, this.path, this.trip);
    },
    contractSign() {
      return pathOr(null, ['registered_as_potential'], this.trip)
    },
    trip_potential_participant() {
      return pathOr(false, ['trip_potential_participant'], this.trip)
    },
    contract() {
      return pathOr(false, ['props', 'contract'], this.trip_potential_participant)
    },
    state() {
      return pathOr(null, ['props', 'status'], this.trip_potential_participant)
    },
    isActual() {
      return this.state === ACTUAL_STATE && !this.contractReject
    },
    contractReject() {
      return pathOr(null, ['props', 'request_to_cancel_contract'], this.trip_potential_participant)
    },
    isContractRejected() {
      return this.contractReject
    },
    isContractFullyRejected() {
      return this.state === ARCHIVED_STATE
    },
    isContractCanBeSigned() {
      return !this.contractSign
    },
    isContractCanBeRejected() {
      return this.contractSign && this.isActual && this.trip_potential_participant && !this.isContractRejected
    },
    isContractWaitToSigned() {
      return this.contractSign && !this.isActual && this.trip_potential_participant && !this.isContractRejected
    },
    action() {
      if (this.isContractCanBeRejected) {
        return this.updateRequest
      }
      if (!this.contractSign) {
        return this.makeRequest
      }
      return () => {}
    },
  },
  methods: {
    ...mapActions('trip', ['getTrip']),
    makeRequest() {
      return tripParticipantCreateResource.execute({}, {
        trip: this.trip_id,
        plan: this.plan_id,
      }).then(() => {
        window.location.reload()
      })
    },
    async updateRequest() {
      const question = this._('Вы действительно хотите расторгнуть договор?')
      try {
        await confirmWithPromise(question, this)
        tripParticipantUpdateResource.execute({
          id: pathOr(null, ['props', 'id'], this.trip_potential_participant),
        }, {
          trip: this.trip_id,
          request_to_cancel_contract: true,
        }).then(() => {
          window.location.reload()
        })
      } catch (e) {
        console.error(e)
      }
    },
  },
}

</script>
