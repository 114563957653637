import {
  createResource,
  optionsResource,
  postResource,
  deleteResource,
  baseResource,
} from '@resource/resource'

import {
  simpleTransformer,
} from '@resource/transformer'

import {
  prefixAPI,
} from '@resource/api'

import {
  BASE_AJAX_URL_POSTFIX,
  order,
} from '@api/catalog.service'

import {
  friendlyUrlGenerator as UrlGenerator,
} from '@aspectus/vue-friendly'

const MODEL = 'events'

const EVENTS_LIST_URL = '/events/'
export const eventsListResource = UrlGenerator([EVENTS_LIST_URL, BASE_AJAX_URL_POSTFIX], baseResource, order)

const ATTRIBUTES_URL = prefixAPI('/filter/', MODEL)
export const attributesResource = createResource(
  ATTRIBUTES_URL
)
const EVENT_REQUEST_URL = prefixAPI('/requests/create/', MODEL)
export const createEventRequest = createResource(
  EVENT_REQUEST_URL, postResource
)
const SUBSCRIPTION_URL = prefixAPI('/subscribers/create/', MODEL)
export const MakeSubscription = createResource(
  SUBSCRIPTION_URL, postResource
)
const UNSUBSCRIPTION_URL = prefixAPI('/subscribers/destroy/{id}/', MODEL)
export const DeleteSubscription = createResource(
  UNSUBSCRIPTION_URL, deleteResource, simpleTransformer
)
